var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('div',{staticClass:"accounts",attrs:{"id":"category"}},[_c('Header'),_vm._v(" "),_c('div',{staticClass:"category-inner"},[_c('div',{staticClass:"page-wrap"},[_c('div',{staticClass:"page-content"},[_c('search'),_vm._v(" "),_c('div',{staticClass:"title"},[_vm._v("\n            Top Accounts\n            "),_c('span',{staticClass:"sub"},[_vm._v(_vm._s(_vm.totalItems))]),_vm._v(" "),_c('div',{staticClass:"btn-refresh"},[_c('reload-button',{style:('margin-left: 8px;'),attrs:{"action":_vm.reload}})],1)]),_vm._v(" "),_c('data-table',{attrs:{"trans-data":_vm.data || [],"is-loading":_vm.isLoading,"css":_vm.dataTableCss},scopedSlots:_vm._u([{key:"list",fn:function({ row }){return [_c('td',[_c('div',[_vm._v(_vm._s(row.rank))])]),_vm._v(" "),_c('td',[_c('div',[_c('account-link',{attrs:{"css":_vm.accountLinkCss,"to-link":`/account/${row.hash}/`,"address":row.hash.toString()}})],1)]),_vm._v(" "),_c('td',[_c('div',{domProps:{"innerHTML":_vm._s(
                    _vm.$options.filters.formatBigNumAmount(
                      row.balance,
                      false,
                      6,
                      18,
                      true
                    )
                  )}})]),_vm._v(" "),_c('td',[_c('div',[_vm._v(_vm._s(row.percentage)+"%")])]),_vm._v(" "),_c('td',[_c('div',{staticClass:"tooltipped tooltipped-se tooltipped-align-left-2",attrs:{"aria-label":_vm.moment(row.ts).format('dddd, MMMM Do YYYY, HH:mm:ss')}},[_vm._v("\n                  "+_vm._s(_vm.moment(row.ts).format('YYYY-MM-DD HH:mm:ss'))+"\n                ")])])]}}])},[_c('template',{slot:"desc"},[_c('div',{staticClass:"desc"},[_vm._v("Total aergo supply : 500,000,000")])]),_vm._v(" "),(_vm.error)?_c('template',{slot:"error"},[_c('div',{staticClass:"error transactions show"},[_vm._v("\n                "+_vm._s(_vm.error)+"\n              ")])]):_vm._e(),_vm._v(" "),_vm._l((_vm.headers),function(header){return _c('template',{slot:"header"},[_c('th',[_c('div',[_vm._v(_vm._s(header.text))])])])}),_vm._v(" "),_vm._v(" "),_c('pagination',{attrs:{"slot":"pagination","css":_vm.paginationCss,"page":_vm.currentPage,"total-items":_vm.limitPageTotalCount,"itemsPerPage":_vm.itemsPerPage},on:{"onUpdate":_vm.changePage,"updateCurrentPage":_vm.updateCurrentPage},slot:"pagination"})],2)],1)])]),_vm._v(" "),_c('Footer')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }