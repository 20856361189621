var render = function render(){var _vm=this,_c=_vm._self._c;return _c('data-table',{attrs:{"trans-data":_vm.data || [],"is-loading":_vm.isLoading,"css":_vm.dataTableCss},scopedSlots:_vm._u([{key:"list",fn:function({ row }){return [_c('td',[_c('div',[_c('router-link',{staticClass:"block",attrs:{"to":`/block/${row.blockno}/`}},[_vm._v(_vm._s(row.blockno))])],1)]),_vm._v(" "),_c('td',[_c('div',{staticClass:"tooltipped tooltipped-se tooltipped-align-left-2",attrs:{"aria-label":_vm.moment(row.ts).format('dddd, MMMM Do YYYY, HH:mm:ss')}},[_vm._v("\n        "+_vm._s(_vm.moment(row.ts).format('YYYY-MM-DD HH:mm:ss'))+"\n      ")])]),_vm._v(" "),_c('td',[(
          ![
            '1111111111111111111111111111111111111111111111111111',
            'MINT',
          ].includes(`${row.from}`.toUpperCase())
        )?_c('account-link',{attrs:{"css":_vm.accountLinkCss,"to-link":`/account/${row.from}/`,"address":row.from.toString(),"name":_vm.$options.filters.formatEllipsisText(row.from, 30)}}):_c('div',{staticClass:"txt-center"},[_c('span',{staticClass:"txt-ellipsis"},[_vm._v("MINT")])])],1),_vm._v(" "),_c('td',[_c('div',[_c('img',{staticClass:"arrow",attrs:{"src":require("@assets/img/ic-arrow-black@3x.png")}})])]),_vm._v(" "),_c('td',[(
          ![
            '1111111111111111111111111111111111111111111111111111',
            'MINT',
          ].includes(`${row.to}`.toUpperCase())
        )?_c('account-link',{attrs:{"css":_vm.accountLinkCss,"to-link":`/account/${row.to}/`,"address":row.to.toString(),"name":_vm.$options.filters.formatEllipsisText(row.to, 30)}}):_c('div',{staticClass:"txt-center"},[_c('span',{staticClass:"txt-ellipsis"},[_vm._v("BURN")])])],1),_vm._v(" "),_c('td',[_c('div',[(!row.image_url)?_c('span',{staticClass:"identicon default"}):_c('span',{staticClass:"identicon"},[_c('img',{attrs:{"src":row.image_url}})]),_vm._v(" "),_c('router-link',{staticClass:"address",attrs:{"to":`/nft/${row.address}`}},[_vm._v("\n          "+_vm._s(`${row.name} (${row.symbol})`)+"\n        ")])],1)]),_vm._v(" "),_c('td',[_c('div',[_c('router-link',{staticClass:"address",attrs:{"to":`/nft/${row.address}/?tx=inventory&keyword=${row.token_id}`}},[_vm._v("\n          "+_vm._s(row.token_id)+"\n        ")])],1)])]}}])},[(_vm.error)?_c('template',{slot:"error"},[_c('div',{staticClass:"error transactions show"},[_vm._v("\n      "+_vm._s(_vm.error)+"\n    ")])]):_vm._e(),_vm._v(" "),_vm._l((_vm.headers),function(header){return _c('template',{slot:"header"},[(header.value === 'arrow')?_c('th'):_c('th',[_c('div',[_vm._v(_vm._s(header.text))])])])}),_vm._v(" "),_vm._v(" "),_c('pagination',{attrs:{"slot":"pagination","css":_vm.paginationCss,"page":_vm.currentPage,"total-items":_vm.limitPageTotalCount,"itemsPerPage":_vm.itemsPerPage},on:{"onUpdate":_vm.changePage,"updateCurrentPage":_vm.updateCurrentPage},slot:"pagination"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }