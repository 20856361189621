var render = function render(){var _vm=this,_c=_vm._self._c;return _c('data-table',{attrs:{"trans-data":_vm.data || [],"is-loading":_vm.isLoading,"css":_vm.dataTableCss},scopedSlots:_vm._u([{key:"list",fn:function({ row }){return [_c('td',[_c('div',[(!row.image_url)?_c('span',{staticClass:"identicon default"}):_c('span',{staticClass:"identicon"},[_c('img',{attrs:{"src":row.image_url}})]),_vm._v(" "),_c('router-link',{staticClass:"block",attrs:{"to":`/nft/${row.hash}/`},domProps:{"innerHTML":_vm._s(row.selectedName)}})],1)]),_vm._v(" "),_c('td',[_c('div',{domProps:{"innerHTML":_vm._s(row.selectedSymbol)}})]),_vm._v(" "),_c('td',[_c('div',[_c('Identicon',{staticClass:"mini-identicon",attrs:{"text":row.hash,"size":"18"}}),_vm._v(" "),_c('router-link',{staticClass:"address txt-ellipsis",attrs:{"to":`/account/${row.hash}/`}},[_vm._v("\n          "+_vm._s(row.hash)+"\n        ")])],1)]),_vm._v(" "),_c('td',[_c('div',{domProps:{"innerHTML":_vm._s(
        row.supply,
          // $options.filters.formatBigNumAmount(
          //   row.supply,
          //   false,
          //   6,
          //   row.decimals
          // )
        )}})]),_vm._v(" "),_c('td',[_c('div',[_vm._v(_vm._s(row.total_transfer))])])]}}])},[_c('template',{slot:"desc"},[_c('div',{staticClass:"search-result"},[_vm._v("\n      Showing result for : \""+_vm._s(_vm.keyword)+"\" "+_vm._s(_vm.totalItems)+" Result(s)\n    ")])]),_vm._v(" "),(_vm.error)?_c('template',{slot:"error"},[_c('div',{staticClass:"error nfts show"},[_vm._v("\n      "+_vm._s(_vm.error)+"\n    ")])]):_vm._e(),_vm._v(" "),_vm._l((_vm.headers),function(header){return _c('template',{slot:"header"},[_c('th',[_c('div',[_vm._v(_vm._s(header.text))])])])}),_vm._v(" "),_vm._v(" "),_c('template',{slot:"more"},[_c('div',{staticClass:"btn-wrap"},[_c('button',{on:{"click":function($event){return _vm.viewAllNfts(_vm.$route.query.keyword)}}},[_vm._v("\n        More Nfts\n      ")])])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }