import { render, staticRenderFns } from "./NftInventoryTable.vue?vue&type=template&id=48894e14&scoped=true"
import script from "./NftInventoryTable.vue?vue&type=script&lang=js"
export * from "./NftInventoryTable.vue?vue&type=script&lang=js"
import style0 from "./NftInventoryTable.vue?vue&type=style&index=0&id=48894e14&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48894e14",
  null
  
)

export default component.exports