var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-wrap latest-transactions-table"},[_c('div',{staticClass:"title"},[_vm._v("Latest Transactions")]),_vm._v(" "),(_vm.error)?_c('div',{staticClass:"error transactions show"},[_vm._v("\n    "+_vm._s(_vm.error)+"\n  ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"h-scroll-main dark"},[_c('div',{staticClass:"h-scroll-main-inner"},[_c('table',{staticClass:"latest-transactions-table lastest-top",class:!_vm.isConnected && 'loading'},[_c('thead',[_c('tr',[_vm._m(0),_vm._v(" "),_c('th',{style:({ minWidth: '120px' })},[_c('div',[_vm._v("Time")])]),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3)])]),_vm._v(" "),_c('tbody',[(!_vm.isConnected)?_c('tr',{staticClass:"hidden loading"},[_c('td',{attrs:{"colspan":"100%"},domProps:{"innerHTML":_vm._s(_vm.connectionStatusMessage)}})]):_vm._l((_vm.syncedTransactions),function(tx,index){return _c('tr',{key:tx.hash},[_c('td',[_c('div',{staticClass:"tooltipped tooltipped-se tooltipped-align-left-2",attrs:{"aria-label":tx.hash}},[_c('router-link',{staticClass:"address txt-ellipsis",attrs:{"to":`/transaction/${tx.hash}`}},[_vm._v(_vm._s(tx.hash))])],1)]),_vm._v(" "),_c('td',[_c('div',{staticClass:"tooltipped tooltipped-s",attrs:{"aria-label":_vm.moment(tx.ts).format('dddd, MMMM Do YYYY, HH:mm:ss')}},[_vm._v("\n                "+_vm._s(_vm.moment(tx.ts || tx.block.header.timestamp / 1000000).format(
                    'YYYY-MM-DD HH:mm:ss'
                  ))+"\n              ")])]),_vm._v(" "),_c('td',[_c('div',{staticClass:"tooltipped tooltipped-s",attrs:{"aria-label":tx.from}},[_c('router-link',{staticClass:"address",attrs:{"to":`/account/${tx.from}`}},[_vm._v("\n                  "+_vm._s(_vm.resizeFormater(tx.from))+"\n                ")])],1)]),_vm._v(" "),_vm._m(4,true),_vm._v(" "),_c('td',[(tx.to.length !== 0)?_c('div',{staticClass:"tooltipped tooltipped-sw tooltipped-align-right-2",attrs:{"aria-label":tx.to}},[_c('router-link',{staticClass:"address",attrs:{"to":`/account/${tx.to}`}},[_vm._v("\n                  "+_vm._s(_vm.resizeFormater(tx.to))+"\n                ")])],1):_c('div',[_c('span',{staticClass:"address"},[_vm._v(_vm._s(tx.category === 'multicall'
                    ? 'MultiCall'
                    : 'Contract Creation'))])])])])})],2)])])]),_vm._v(" "),_c('div',{staticClass:"btn-wrap"},[_c('button',{on:{"click":function($event){return _vm.viewAllTransactions()}}},[_vm._v("view all")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('div',[_vm._v("TX Hash")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('div',[_vm._v("From")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('div')])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('div',[_vm._v("To")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('div',[_c('img',{staticClass:"arrow",attrs:{"src":require("@assets/img/ic-arrow@3x.png")}})])])
}]

export { render, staticRenderFns }