var render = function render(){var _vm=this,_c=_vm._self._c;return _c('data-table',{attrs:{"trans-data":_vm.data || [],"is-loading":_vm.isLoading,"css":_vm.dataTableCss},scopedSlots:_vm._u([{key:"list",fn:function({ row }){return [_c('td',[_c('div',[_vm._v(_vm._s(row.rank))])]),_vm._v(" "),_c('td',[_c('account-link',{attrs:{"css":_vm.accountLinkCss,"to-link":`/account/${row.account}/`,"address":row.account.toString()}})],1),_vm._v(" "),_c('td',[_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$options.filters.formatBigNumAmount(
            row.balance,
            false,
            6,
            row.decimals
          )
        )}})]),_vm._v(" "),_c('td',[_c('div',[_vm._v(_vm._s(row.percentage)+"%")])])]}}])},[(_vm.error)?_c('template',{slot:"error"},[_c('div',{staticClass:"error transactions show"},[_vm._v("\n      "+_vm._s(_vm.error)+"\n    ")])]):_vm._e(),_vm._v(" "),_vm._l((_vm.headers),function(header){return _c('template',{slot:"header"},[(header.value === 'arrow')?_c('th'):_c('th',[_c('div',[_vm._v(_vm._s(header.text))])])])}),_vm._v(" "),_vm._v(" "),_c('pagination',{attrs:{"slot":"pagination","css":_vm.paginationCss,"page":_vm.currentPage,"total-items":_vm.limitPageTotalCount,"itemsPerPage":_vm.itemsPerPage},on:{"onUpdate":_vm.changePage,"updateCurrentPage":_vm.updateCurrentPage},slot:"pagination"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }