var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('div',{staticClass:"bp-list",attrs:{"id":"category"}},[_c('Header'),_vm._v(" "),_c('div',{staticClass:"category-inner"},[_c('div',{staticClass:"page-wrap"},[_c('div',{staticClass:"page-content"},[_c('search'),_vm._v(" "),_c('div',{staticClass:"title"},[_vm._v("Chain Info")]),_vm._v(" "),_c('div',{staticClass:"detail-box"},[_c('div',{staticClass:"table-wrap"},[(_vm.error)?_c('div',{staticClass:"error"},[_vm._v("\n                "+_vm._s(_vm.error)+"\n              ")]):_vm._e(),_vm._v(" "),(!_vm.error && _vm.chainInfo && _vm.chainInfo.chainid)?_c('table',{staticClass:"bp-detail"},[_c('tbody',[_c('tr',[_vm._m(0),_vm._v(" "),_c('td',[_c('div',{staticClass:"chain-id"},[_vm._v("\n                        "+_vm._s(_vm.chainInfo.chainid.magic)+" ("+_vm._s(_vm.chainInfo.chainid.public ? 'public' : 'private')+",\n                        "+_vm._s(_vm.chainInfo.chainid.mainnet ? 'main' : 'non-main')+")\n                      ")])])]),_vm._v(" "),_c('tr',[_vm._m(1),_vm._v(" "),_c('td',[_c('div',[_vm._v(_vm._s(_vm.chainInfo.maxblocksize))])])]),_vm._v(" "),_c('tr',[_vm._m(2),_vm._v(" "),_c('td',[_c('div',{domProps:{"innerHTML":_vm._s(
                          _vm.$options.filters.formatToken(
                            _vm.chainInfo.maxtokens,
                            'aergo'
                          )
                        )}})])]),_vm._v(" "),_c('tr',[_vm._m(3),_vm._v(" "),_c('td',[_c('div',[_vm._v(_vm._s(_vm.chainInfo.chainid.consensus))])])]),_vm._v(" "),_c('tr',[_vm._m(4),_vm._v(" "),_c('td',[_c('div',[_vm._v(_vm._s(_vm.bpNumber))])])])])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"table-wrap"},[(_vm.error)?_c('div',{staticClass:"error"},[_vm._v("\n                "+_vm._s(_vm.error)+"\n              ")]):_vm._e(),_vm._v(" "),(!_vm.error && _vm.chainInfo && _vm.chainInfo.chainid)?_c('table',{staticClass:"bp-detail"},[_c('tbody',[_c('tr',[_vm._m(5),_vm._v(" "),_c('td',[_c('div',{domProps:{"innerHTML":_vm._s(
                          _vm.$options.filters.formatToken(
                            _vm.chainInfo.stakingminimum,
                            'aergo'
                          )
                        )}})])]),_vm._v(" "),_c('tr',[_vm._m(6),_vm._v(" "),_c('td',{staticClass:"txt-ellipsis"},[_c('div',{domProps:{"innerHTML":_vm._s(
                          _vm.$options.filters.formatToken(
                            _vm.chainInfo.stakingtotal,
                            'aergo'
                          )
                        )}})])]),_vm._v(" "),_c('tr',[_vm._m(7),_vm._v(" "),_c('td',[_c('div',{domProps:{"innerHTML":_vm._s(
                          _vm.$options.filters.formatToken(
                            _vm.chainInfo.nameprice,
                            'aergo'
                          )
                        )}})])]),_vm._v(" "),_c('tr',[_vm._m(8),_vm._v(" "),_c('td',[_c('div',{domProps:{"innerHTML":_vm._s(
                          _vm.$options.filters.formatToken(
                            _vm.chainInfo.gasprice,
                            'aer'
                          )
                        )}})])])])]):_vm._e()])]),_vm._v(" "),(_vm.chainInfo.chainid && _vm.chainInfo.chainid.consensus == 'dpos')?_c('div',{staticClass:"table-wrap"},[_c('div',{staticClass:"title"},[_vm._v("\n              BP List\n              "),_c('div',{staticClass:"btn-refresh"},[_c('ReloadButton',{attrs:{"action":_vm.loadVotes}})],1)]),_vm._v(" "),_c('div',{staticClass:"h-scroll"},[_c('table',{staticClass:"bp-list-table main-net",class:!_vm.votesList || !_vm.votesList.length ? 'loading' : ''},[_vm._m(9),_vm._v(" "),(_vm.votesList && _vm.votesList.length)?_c('tbody',_vm._l((_vm.votesList),function(item,index){return _c('tr',{key:item.candidate,class:{
                      focus: _vm.$route.query.highlight === item.candidate,
                      'last-selected': _vm.bpNumber && _vm.bpNumber === index + 1,
                    },attrs:{"tabindex":_vm.$route.query.highlight === item.candidate && index}},[_c('td',[_vm._v(_vm._s(index + 1))]),_vm._v(" "),_c('td',[_c('div',[_c('Identicon',{staticClass:"mini-identicon",attrs:{"text":item.candidate,"size":"18"}}),_vm._v("\n                        "+_vm._s(item.candidate)+"\n                      ")],1)]),_vm._v(" "),_c('td',[_c('div',[_vm._v("\n                        "+_vm._s(item.amount.toUnit('aergo').toString())+"\n                      ")])])])}),0):_vm._e()])])]):_vm._e(),_vm._v(" "),(_vm.chainInfo.chainid && _vm.chainInfo.chainid.consensus == 'raft')?_c('div',{staticClass:"table-wrap"},[_c('div',{staticClass:"title"},[_vm._v("\n              BP List\n              "),_c('div',{staticClass:"btn-refresh"},[_c('ReloadButton',{attrs:{"action":_vm.loadConsensus}})],1)]),_vm._v(" "),_c('div',{staticClass:"h-scroll"},[_c('table',{staticClass:"bp-list-table",class:!_vm.consensusInfo ? 'loading' : ''},[_vm._m(10),_vm._v(" "),(_vm.consensusInfo && _vm.consensusInfo.bpsList.length)?_c('tbody',_vm._l((_vm.consensusInfo.bpsList),function(item,index){return _c('tr',{key:item.PeerID,class:{
                      focus: _vm.$route.query.highlight === item.PeerID,
                      'last-selected': _vm.bpNumber && _vm.bpNumber === index + 1,
                    }},[_c('td',[_c('div',[_vm._v(_vm._s(index + 1))])]),_vm._v(" "),_c('td',[_c('div',[_vm._v(_vm._s(item.Name))])]),_vm._v(" "),_c('td',[_c('div',[_vm._v(_vm._s(item.RaftID))])]),_vm._v(" "),_c('td',[_c('div',[_c('Identicon',{staticClass:"mini-identicon",attrs:{"text":item.PeerID,"size":"18"}}),_vm._v("\n                        "+_vm._s(item.PeerID)+"\n                      ")],1)]),_vm._v(" "),_c('td',[_c('div',[(item.Name == _vm.consensusInfo.info.Leader)?_c('span',{staticClass:"boxicon gray"},[_vm._v("Leader")]):_vm._e()])])])}),0):_vm._e()])])]):_vm._e()],1)])]),_vm._v(" "),_c('Footer')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('div',[_vm._v("Chain ID")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('div',[_vm._v("Maximum block size")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('div',[_vm._v("Total token supply")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('div',[_vm._v("Consensus")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('div',[_vm._v("BP number")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('div',[_vm._v("Min. staking amount")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('div',[_vm._v("Total staked amount")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('div',[_vm._v("Nameprice")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',[_c('div',[_vm._v("Gasprice")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_c('div',[_vm._v("POS.")])]),_vm._v(" "),_c('th',[_c('div',[_vm._v("PEER ID")])]),_vm._v(" "),_c('th',[_c('div',[_vm._v("VOTES")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_c('div',[_vm._v("POS.")])]),_vm._v(" "),_c('th',[_c('div',[_vm._v("NAME")])]),_vm._v(" "),_c('th',[_c('div',[_vm._v("RAFT ID")])]),_vm._v(" "),_c('th',[_c('div',[_vm._v("PEER ID")])]),_vm._v(" "),_c('th',[_c('div',[_vm._v("STATUS")])])])])
}]

export { render, staticRenderFns }